import {onDomReady} from "../../components/dynamic/observer";
import {FreeMode, Pagination, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
function init() {
    document.querySelectorAll('[data-product-slider-container]')
        .forEach((el) => {
            const slider = new Swiper(el.querySelector('[data-product-slider]'), {
                modules: [Navigation, FreeMode, Pagination],
                spaceBetween: 14,
                speed: 700,
                navigation: {
                    prevEl: el.querySelector('[data-product-slider-left]'),
                    nextEl: el.querySelector('[data-product-slider-right]'),
                },
                pagination: {
                    el: el.querySelector('[data-product-slider-pagination]'),
                    type: 'bullets',
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        loop: false,
                        freeMode: true,
                        slidesPerView: 1.5,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                },
                on: {
                    init: function (swiper) {
                        if (window.innerWidth >= swiper.currentBreakpoint) {
                            if (swiper.slides.length <= swiper.params.slidesPerView) {
                                swiper.navigation.prevEl.parentElement.classList.add('_hidden');
                            }
                        }
                    },
                }
            });
        })
}

onDomReady(init);