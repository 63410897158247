/**
 * Используем https://photoswipe.com/getting-started/
 *
 * Достаточно удобно и кастомизируемо
 * Пример кода шаблона в uikit
 * */

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const leftArrowSVGString = '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 100 125" width="100" height="125"><<path d="M0 45L45 0L48 3L6 45L48 87L45 90L0 45Z" fill="black"/></svg>';

const options = {
    arrowPrevSVG: leftArrowSVGString,
    arrowNextSVG: leftArrowSVGString,
    counter: false,

    gallery: '#gallery--getting-started',
    children: 'li',
    pswpModule: () => import('photoswipe')
}

const lightbox = new PhotoSwipeLightbox(options);
lightbox.init();