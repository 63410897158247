import {onDomReady} from "../../components/dynamic/observer";
import {FreeMode, Pagination, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
function init() {
    document.querySelectorAll('[data-reviews-slider-container]')
        .forEach((el) => {
            const slider = new Swiper(el.querySelector('[data-reviews-slider]'), {
                modules: [ Navigation, FreeMode, Pagination],
                slidesPerView: 1,
                speed: 700,
                autoHeight: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    prevEl: el.querySelector('[data-reviews-slider-left]'),
                    nextEl: el.querySelector('[data-reviews-slider-right]'),
                },
            });
        })
}

onDomReady(init);