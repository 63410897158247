require('../scss/app.scss');
require('./svg/svg.js');

require('../fonts/Roboto_Flex/stylesheet.css');

window.noZensmooth = true;

// require('../vue/app');
// require('@vueform/slider/themes/default.css')

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/photoswipe');

require('./accordion/initializator');
require('./accordion/switchable-accordion');
require('./sliders/product-slider');
require('./sliders/reviews-slider');

require('./header/header');
require('./field/phone.js');
require('./field/floating.js');
require('./field/multi-uploadable.js');
require('./hero');
require('./common-map/map');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');