import IMask from "imask";
import axios from "axios";
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = 'true';

    const dispatchMask = IMask(input, {
      mask: '+{7} (000) 000-00-00',
      prepare: function (str) {
        if (!this._value && str === '8') {
          return '7'
        }
        return str;
      },
    });

    dispatchMask.on('complete', () => {
      if (input.dataset.leadUrl) {
        axios.post(input.dataset.leadUrl, {
          phone: input.value
        });
      }
    });
  });
};

onDomReady(() => {
  initPhoneField();
})

onDomChanges(() => {
  initPhoneField();
})
