import { onDomReady } from '../../components/dynamic/observer';
import {scrollToTarget} from "../../components/scroll/scroll";
class Header {
    constructor(selector) {
        this.header = selector;
        this.headerHeight = this.header.offsetHeight;
        this.headerContainer = this.header.querySelector('[data-header-container]');
        this.headerInner = this.header.querySelector('[data-header-inner]');
        this.dropMenu = selector.querySelector('[data-drop-menu]');
        this.dropMenuLinks = this.header.querySelectorAll('[data-drop-menu-link]');

        this.hamburger = selector.querySelector('[data-hamburger]');
        this.footer = document.querySelector('[data-footer]');

        this.aboutMenu = document.querySelector('[data-about-menu]');

        this.isOpened = false;
        this.scrollTop = 0;

        this.eventListeners();
    }

    open() {
        this.isOpened = true;
        this.header.classList.add('_opened');
        this.dropMenu.classList.add('_opened');
        this.hamburger.classList.add('_opened');
    }

    close() {
        this.isOpened = false;
        this.header.classList.remove('_opened');
        this.dropMenu.classList.remove('_opened');
        this.hamburger.classList.remove('_opened');
        document.body.style.overflow = '';
        document.body.style.height = '';
    }

    addSticky() {
        const instance = this;
        if (instance.scrollTop > 150) {
            this.header.classList.add('_sticky');
        } else {
            instance.header.classList.remove('_sticky');
        }
    }

    eventListeners() {
        this.hamburger.addEventListener('click', () => {
            this.isOpened ? this.close() : this.open();
        });

        document.addEventListener('click', (e) => {
            if (this.isOpened && this.dropMenu === e.target) {
                this.close();
            }
        });

        window.addEventListener('scroll', () => {
            const instance = this;
            instance.scrollTop = window.scrollY;

            instance.addSticky();
            instance.lastScrollTop = instance.scrollTop;
        });

        this.dropMenuLinks.forEach((item) => {
            item.addEventListener('click', () => {
                if (!this.isOpened) {
                    const target = document.querySelector(item.getAttribute('href'));
                    const offset = -75;

                    scrollToTarget(target, offset);
                } else {
                    this.close();
                    setTimeout(() => {
                        const target = document.querySelector(item.getAttribute('href'));
                        const offset = -75;
                        scrollToTarget(target, offset);
                    }, 500);
                }
            });
        });
    }
}

onDomReady(() => {
    const headerSelector = document.querySelector('[data-header]');
    if (headerSelector) {
        new Header(headerSelector);
    }
});